import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from '../store/index.js';
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => {});
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "404",
    component: () => import("../views/NotFound.vue"),
    meta: {
      title: "404",
    },
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login", // 登录
    name: "/login",
    component: () => import("../views/Login"),
  },
  {
    path: "/home", //首页左侧菜单栏+大框架
    name: "home",
    redirect: "/home/index",
    component: HomeView,
    children: [
      {
        path: "index",
        name: "/home/index", //首页
        component: () => import("../views/Index.vue"),
        meta: {
          // breadcrumbs: ["首页"],
        },
      },
      {
        path: "Sign/punch_in",
        name: "Sign/punch_in", //我的/出勤报道
        component: () => import("../views/wd/cqbdView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "出勤报道" },
            // { title: "出勤报道", path: "/home/Sign/punch_in" },
          ],
        },
      },
      {
        path: "Signnew/mysign",
        name: "Signnew/mysign", //我的/我的打卡记录
        component: () => import("../views/wd/wddkjlView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "我的打卡记录" },
          ],
        },
      },
      {
        path: "payroll/mypayroll",
        name: "payroll/mypayroll", //我的/我的工资
        component: () => import("../views/wd/wdgzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "我的工资" },
          ],
        },
      },
      {
        path: "Payroll/mygz",
        name: "Payroll/mygz", //我的/我的工资条
        component: () => import("../views/wd/wdgztView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "我的工资条" },
          ],
        },
      },
      {
        path: "Attendance/myovertime",
        name: "Attendance/myovertime", //我的/我的加班
        component: () => import("../views/wd/wdjbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "我的加班" },
          ],
        },
      },
      {
        path: "Attendance/myvacation",
        name: "Attendance/myvacation", //我的/我的请假
        component: () => import("../views/wd/wdqjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "我的请假" },
          ],
        },
      },
      {
        path: "attendance/myprevacation",
        name: "attendance/myprevacation", //首页待办事项中事前请假
        component: () => import("../views/wd/sqqjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "事前请假" },
          ],
        },
      },
      {
        path: "attendance/mydailyreportlist",
        name: "attendance/mydailyreportlist", //我的/每日健康上报
        component: () => import("../views/wd/mrjksbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "每日健康上报" },
          ],
        },
      },
      {
        path: "profile/password",
        name: "profile/password", //我的/修改密码
        component: () => import("../views/wd/xgmmView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "修改密码" },
          ],
        },
      },
      {
        path: "Attendance/workhoursbank",
        name: "Attendance/workhoursbank", //我的/我的工时银行
        component: () => import("../views/wd/wdgsyhView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "我的工时银行" },
          ],
        },
      },
      {
        path: "cert/certlist",
        name: "cert/certlist", //我的/我的证书
        component: () => import("../views/wd/wdzsView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "我的证书" },
          ],
        },
      },
      {
        path: "profile/baseinfo",
        name: "profile/baseinfo", //我的/基本信息
        component: () => import("../views/wd/jbxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "基本信息" },
          ],
        },
      },
      {
        path: "userconfig/index",
        name: "userconfig/index", //我的/使用习惯
        component: () => import("../views/wd/syxgView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "使用习惯" },
          ],
        },
      },
      {
        path: "todo/index",
        name: "todo/index", //我的/待办
        component: () => import("../views/wd/dbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "待办" },
          ],
        },
      },
      // {
      //   path: "Confidentiality/index",
      //   name: "Confidentiality/index", //我的/保密承诺
      //   component: () => import("../views/wd/bmcnView.vue"),
      //   meta: {
      //     breadcrumbs: [
      //       { title: "首页", path: "/home/index" },
      //       { title: "我的" },
      //       { title: "保密承诺" },
      //     ],
      //   },
      // },
      {
        path: "contact/index",
        name: "contact/index", //我的/联系人
        component: () => import("../views/wd/lxrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "联系人" },
          ],
        },
      },
      {
        path: "schedule/index",
        name: "schedule/index", //我的/日程
        component: () => import("../views/wd/rcView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "日程" },
          ],
        },
      },
      {
        path: "group/index",
        name: "group/index", //我的/群组
        component: () => import("../views/wd/qzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "群组" },
          ],
        },
      },
      {
        path: "flow/index",
        name: "flow/index", //申请/填写新的申请
        component: () => import("../views/sq/txxdsqView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请" },
            { title: "填写新的申请" },
          ],
        },
      },
      {
        path: "sq/txxdsq/hb",
        name: "sq/txxdsq/hb", //申请/填写新的申请/换班
        component: () => import("../views/sq/txxdsq/hbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请" },
            { title: "填写新的申请", path: "/home/flow/index" },
            { title: "换班" },
          ],
        },
      },
      {
        path: "sq/txxdsq/sqxq",
        name: "sq/txxdsq/sqxq", //申请/填写新的申请/各类申请的申请详情页面
        component: () => import("../views/sq/txxdsq/sqxqView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请" },
            { title: "填写新的申请", path: "/home/flow/index" },
            { title: "申请详情" },
          ],
        },
      },
      {
        path: "flow/folder/fid=darft",
        name: "flow/folder/fid=darft", //申请/草稿箱
        component: () => import("../views/sq/cgxView.vue"),
        meta: {
          keepAlive: true, //保活
          isback: false, //从详情页返回isback为true其他均为false
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请" },
            { title: "草稿箱" },
          ],
        },
      },
      {
        path: "flow/folder/cgxdetails/:id",
        name: "flow/folder/cgxdetails", //申请/草稿箱/草稿箱详情
        component: () => import("../views/sq/cgxxqView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请" },
            { title: "草稿箱" },
            { title: "草稿箱详情" },
          ],
        },
      },
      {
        path: "flow/folder/fid=submit",
        name: "flow/folder/fid=submit", //申请/我提交的申请
        component: () => import("../views/sq/wtjdsqView.vue"),
        meta: {
          keepAlive: true, //保活
          isback: false, //从详情页返回isback为true其他均为false

          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请" },
            { title: "我提交的申请" },
          ],
        },
      },
      {
        path: "flow/folder/details/:id",
        name: "flow/folder/details", //申请、审批/申请、审批详情
        component: () => import("../views/sq/sqspxqView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请、审批" },
            { title: "申请、审批详情" },
            { title: "详情" },
          ],
        },
      },
      {
        path: "flow/folder/detailsnew/:id",
        name: "flow/folder/detailsnew", //申请、审批/申请、审批详情
        component: () => import("../views/sq/sqspxqViewNew.vue"),//测试新支出凭证
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "申请、审批" },
            { title: "申请、审批详情" },
            { title: "详情" },
          ],
        },
      },
      {
        path: "flow/folder/fid=confirm",
        name: "flow/folder/fid=confirm", //审批/待审批
        component: () => import("../views/sp/dspView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "待审批" },
          ],
        },
      },
      {
        path: "flow/folder/fid=finish",
        name: "flow/folder/fid=finish", //审批/已审批
        component: () => import("../views/sp/yspView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "已审批" },
          ],
        },
      },
      {
        path: "flow/folder/fid=cancel",
        name: "flow/folder/fid=cancel", //审批/申请取消
        component: () => import("../views/sp/sqqxView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "申请取消" },
          ],
        },
      },
      {
        path: "flow/folder/fid=dustbin",
        name: "flow/folder/fid=dustbin", //审批/垃圾箱
        component: () => import("../views/sp/ljxView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "垃圾箱" },
          ],
        },
      },
      {
        path: "flow/folder/fid=receive",
        name: "flow/folder/fid=receive", //审批/参阅箱
        component: () => import("../views/sp/cyxView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "参阅箱" },
          ],
        },
      },
      {
        path: "flow/folder/fid=report",
        name: "flow/folder/fid=report", //审批/审批结果
        component: () => import("../views/sp/spjgView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "审批结果" },
          ],
        },
      },
      {
        path: "flow/folder/fid=unclosed",
        name: "flow/folder/fid=unclosed", //财务/待支出
        component: () => import("../views/cw/dzcView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "财务" },
            { title: "待支出" },
          ],
        },
      },
      {
        path: "account/index",
        name: "account/index", //财务/支出明细
        component: () => import("../views/cw/zcmxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "财务" },
            { title: "支出明细" },
          ],
        },
      },
      {
        path: "shift/admit",
        name: "shift/admit", //审批/换班确认
        component: () => import("../views/sp/hbqrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "换班确认" },
          ],
        },
      },
      {
        path: "duty/index",
        name: "duty/index", //审批/设置高级权限/设置高级权限组
        component: () => import("../views/sp/szgjqx/szgjqxzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "设置高级权限" },
            { title: "设置高级权限组" },
          ],
        },
      },
      {
        path: "role/duty",
        name: "role/duty", //审批/设置高级权限/分配高级权限
        component: () => import("../views/sp/szgjqx/fpgjqxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "设置高级权限" },
            { title: "分配高级权限" },
          ],
        },
      },
      {
        path: "FlowType/tag_manage",
        name: "FlowType/tag_manage", //审批/审批事项类别
        component: () => import("../views/sp/spsxlbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "审批事项类别" },
          ],
        },
      },
      {
        path: "flowtype/fixed",
        name: "flowtype/fixed", //审批/设置审批事项/固定事项
        component: () => import("../views/sp/szspsx/gdsxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "设置审批事项" },
            { title: "固定事项" },
          ],
        },
      },
      {
        path: "flowtype/index",
        name: "flowtype/index", //审批/设置审批事项/自定义事项
        component: () => import("../views/sp/szspsx/zdysxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "审批" },
            { title: "设置审批事项" },
            { title: "自定义事项" },
          ],
        },
      },
      {
        path: "Zqgzflow/add",
        name: "Zqgzflow/add", //派遣公司工资发放/提交新申请
        component: () => import("../views/pqgsgzff/tjxdsqView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "提交新申请" },
          ],
        },
      },
      {
        path: "Zqgzflow/folder/fid=deptreport",
        name: "Zqgzflow/folder/fid=deptreport", //派遣公司工资发放/本部门提交的申请
        component: () => import("../views/pqgsgzff/bbmtjdsqView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "本部门提交的申请" },
          ],
        },
      },
      {
        path: "Zqgzflow/folder/fid=closed",
        name: "Zqgzflow/folder/fid=closed", //派遣公司工资发放/财务审核
        component: () => import("../views/pqgsgzff/cwshView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "财务审核" },
          ],
        },
      },
      {
        path: "Zqgzflow/folder/fid=submit",
        name: "Zqgzflow/folder/fid=submit", //派遣公司工资发放/我提交的申请
        component: () => import("../views/pqgsgzff/wtjdsqView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "我提交的申请" },
          ],
        },
      },
      {
        path: "Zqgzflow/folder/fid=report",
        name: "Zqgzflow/folder/fid=report", //派遣公司工资发放/审批结果
        component: () => import("../views/pqgsgzff/spjgView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "审批结果" },
          ],
        },
      },
      {
        path: "Zqgzflow/folder/fid=finish",
        name: "Zqgzflow/folder/fid=finish", //派遣公司工资发放/已审批
        component: () => import("../views/pqgsgzff/yspView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "已审批" },
          ],
        },
      },
      {
        path: "Zqgzflow/folder/fid=confirm",
        name: "Zqgzflow/folder/fid=confirm", //派遣公司工资发放/待审批
        component: () => import("../views/pqgsgzff/dspView.vue"),
        meta: {
          keepAlive: true,
          isback: false,
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "待审批" },
          ],
        },
      },
      {
        path: "Zqgzflow/folder/details/:id",
        name: "Zqgzflow/folder/details", //派遣公司工资发放/各类详情页面
        component: () => import("../views/pqgsgzff/xqView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "派遣公司工资发放" },
            { title: "各类" },
            { title: "详情" },
          ],
        },
      },
      {
        path: "info/list",
        name: "info/list", //公告/全部公告
        component: () => import("../views/gg/qbggView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公告" },
            { title: "全部公告" },
          ],
        },
      },
      {
        path: "info/folder/:id", //公告/公司公告...
        name: "info/folder/gsgg", //如果含有中文 以最后一个/分割去除后面的 例:info/folder/fid=149/fname=公司公告 -> info/folder/fid=149?fname=公司公告
        component: () => import("../views/gg/gsggView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公告" },
            { title: "公司公告" },
          ],
        },
      },
      {
        path: "info/my_info", //公告/我发布的通知
        name: "info/my_info",
        component: () => import("../views/gg/wfbdtzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公告" },
            { title: "我发布的通知" },
          ],
        },
      },
      {
        path: "info/my_sign", //公告/我签收的通知
        name: "info/my_sign",
        component: () => import("../views/gg/wqsdtzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公告" },
            { title: "我签收的通知" },
          ],
        },
      },
      {
        path: "doc/folder/:id", //共享文件/证照...
        name: "doc/folder/zz", //和公告一样 如果含有中文 以最后一个/分割去除后面的 例:doc/folder/fid=164/fname=证照（众齐） -> doc/folder/fid=164?fname=证照（众齐）
        component: () => import("../views/gxwj/zzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "共享文件" },
            { title: "证照" },
          ],
        },
      },
      {
        path: "Info/folder_manage", //公告/管理公告栏
        name: "Info/folder_manage",
        component: () => import("../views/gg/glgglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公告" },
            { title: "管理公告栏" },
          ],
        },
      },
      {
        path: "doc/index", //共享文件/文档搜索
        name: "doc/index",
        component: () => import("../views/gxwj/wdssView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "共享文件" },
            { title: "文档搜索" },
          ],
        },
      },
      {
        path: "doc/folder_manage", //共享文件/文档管理
        name: "doc/folder_manage",
        component: () => import("../views/gxwj/wdglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "共享文件" },
            { title: "文档管理" },
          ],
        },
      },
      {
        path: "payroll/fixed",
        name: "payroll/fixed", //工资/工资固定项
        component: () => import("../views/gz/gzgdxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "工资固定项" },
          ],
        },
      },
      {
        path: "payroll/month",
        name: "payroll/month", //工资/工资月上报项
        component: () => import("../views/gz/gzysbxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "工资月报项" },
          ],
        },
      },
      {
        path: "payroll/insurance",
        name: "payroll/insurance", //工资/工资保险部分
        component: () => import("../views/gz/gzbxbfView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "工资保险项" },
          ],
        },
      },
      {
        path: "payroll/salary",
        name: "payroll/salary", //工资/基础薪资
        component: () => import("../views/gz/gdxzxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "基础薪资" },
          ],
        },
      },
      {
        path: "payroll/final",
        name: "payroll/final", //工资/工资终表
        component: () => import("../views/gz/cxgzbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "工资终表" },
          ],
        },
      },
      {
        path: "payroll/gzlist",
        name: "payroll/gzlist", //工资/工资条列表
        component: () => import("../views/gz/gztlbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "工资条列表" },
          ],
        },
      },
      {
        path: "payroll/importgzlist",
        name: "payroll/importgzlist", //工资/工资条导入和关联
        component: () => import("../views/gz/gztdrhglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "工资条导入和关联" },
          ],
        },
      },
      {
        path: "Payroll/importsix",
        name: "Payroll/importsix", //工资/六项扣除导入
        component: () => import("../views/gz/lxkcdrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "六项扣除导入" },
          ],
        },
      },
      {
        path: "payroll/paysixlist",
        name: "payroll/paysixlist", //工资/六项扣除列表
        component: () => import("../views/gz/lxkclbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "六项扣除列表" },
          ],
        },
      },
      {
        path: "payroll/payrolllist",
        name: "payroll/payrolllist", //工资/应发工资导入和关联
        component: () => import("../views/gz/yfgzdrhglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "工资" },
            { title: "应发工资导入和关联" },
          ],
        },
      },
      {
        path: "user/list",
        name: "user/list", //档案合同/人员信息
        component: () => import("../views/daht/ryxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "人员信息" },
          ],
        },
      },
      {
        path: "user/deptuser",
        name: "user/deptuser", //档案合同/组织结构
        component: () => import("../views/daht/zzjgView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "组织结构" },
          ],
        },
      },
      {
        path: "personalfiles/index",
        name: "personalfiles/index", //档案合同/人员结构
        component: () => import("../views/daht/ryjgView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "人员结构" },
          ],
        },
      },
      {
        path: "entryinfo/index",
        name: "entryinfo/index", //档案合同/入职信息
        component: () => import("../views/daht/rzxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "入职信息" },
          ],
        },
      },
      {
        path: "dimission/index",
        name: "dimission/index", //档案合同/离职信息
        component: () => import("../views/daht/lzxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "离职信息" },
          ],
        },
      },
      {
        path: "leave/list",
        name: "leave/list", //档案合同/离职列表
        component: () => import("../views/daht/lzlbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "离职列表" },
          ],
        },
      },
      {
        path: "leave/newform",
        name: "leave/newform", //档案合同/离职办理
        component: () => import("../views/daht/lzblView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "离职办理" },
          ],
        },
      },
      {
        path: "contract/list",
        name: "contract/list", //档案合同/合同管理
        component: () => import("../views/daht/htglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "合同管理" },
          ],
        },
      },
      {
        path: "contract/retirelist",
        name: "contract/retirelist", //档案合同/退休人员
        component: () => import("../views/daht/txryView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "退休人员" },
          ],
        },
      },
      {
        path: "user/rycz",
        name: "user/rycz", //档案合同/服务器人员查找
        component: () => import("../views/daht/fwqryczView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "服务器人员查找" },
          ],
        },
      },
      {
        path: "cert/list",
        name: "cert/list", //档案合同/证书管理
        component: () => import("../views/daht/zsglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "档案合同" },
            { title: "证书管理" },
          ],
        },
      },
      {
        // path: "daxieyi/dxylist",
        path: "daxieyi/:id",
        name: "daxieyi/dxylist", //大协议/大协议列表
        component: () => import("../views/dxy/dxyView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "大协议" },
            { title: "大协议列表" },
          ],
        },
      },
      {
        path: "Signnew/monthlist",
        name: "Signnew/monthlist", //考勤管理/各部门考勤统计
        component: () => import("../views/kqgl/gbmkqtjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "各部门考勤统计" },
          ],
        },
      },
      {
        path: "Signnew/realreport",
        name: "Signnew/realreport", //考勤管理/实时统计
        component: () => import("../views/kqgl/sstjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "实时统计" },
          ],
        },
      },
      {
        path: "attendance/signdaydetail",
        name: "attendance/signdaydetail", //考勤管理/每日出勤统计
        component: () => import("../views/kqgl/mrcqtjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "每日出勤统计" },
          ],
        },
      },
      {
        path: "attendance/signdaydetail56",
        name: "attendance/signdaydetail56", //考勤管理/每日出勤统计(临时打卡)
        component: () => import("../views/kqgl/mrcqtjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "每日出勤统计(临时打卡)" },
          ],
        },
      },
      {
        path: "attendance/monthlyreport",
        name: "attendance/monthlyreport", //考勤管理/月考勤表（综合工时）
        component: () => import("../views/kqgl/ykqbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "月考勤表（综合工时）" },
          ],
        },
      },
      {
        path: "Attendance/monthlyreport1",
        name: "Attendance/monthlyreport1", //考勤管理/月考勤表（标准工时）
        component: () => import("../views/kqgl/ykqbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "月考勤表（标准工时）" },
          ],
        },
      },
      {
        path: "attendance/monthlyreport56",
        name: "attendance/monthlyreport56", //考勤管理/月考勤表（临时签到）
        component: () => import("../views/kqgl/ykqbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "月考勤表（临时签到）" },
          ],
        },
      },
      {
        path: "attendance/signmonthlydetail",
        name: "attendance/signmonthlydetail", //考勤管理/考勤月报
        component: () => import("../views/kqgl/ykqhzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "考勤月报" },
          ],
        },
      },
      {
        path: "orderlunch/orderlist",
        name: "orderlunch/orderlist", //考勤管理/每日工作餐
        component: () => import("../views/kqgl/mrgzcView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "每日工作餐" },
          ],
        },
      },
      {
        path: "Attendance/allovertime",
        name: "Attendance/allovertime", //考勤管理/加班信息
        component: () => import("../views/kqgl/jbqjxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "加班信息" },
          ],
        },
      },
      {
        path: "attendance/allprevacation",
        name: "attendance/allprevacation", //考勤管理/事前请假信息
        component: () => import("../views/kqgl/jbqjxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "事前请假信息" },
          ],
        },
      },
      {
        path: "Attendance/allvacation",
        name: "Attendance/allvacation", //考勤管理/事后请假信息
        component: () => import("../views/kqgl/jbqjxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "事后请假信息" },
          ],
        },
      },
      {
        path: "attendance/allannul",
        name: "attendance/allannul", //考勤管理/所有人的年假
        component: () => import("../views/kqgl/syrdView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "所有人的年假" },
          ],
        },
      },
      {
        path: "attendance/allannulhistory",
        name: "attendance/allannulhistory", //考勤管理/所有人的年假（历史）
        component: () => import("../views/kqgl/syrdView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "所有人的年假（历史）" },
          ],
        },
      },
      {
        path: "attendance/allworkhoursbank",
        name: "attendance/allworkhoursbank", //考勤管理/所有人的工时银行
        component: () => import("../views/kqgl/syrdView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "所有人的工时银行" },
          ],
        },
      },
      {
        path: "Worklog/dailyreportlist",
        name: "Worklog/dailyreportlist", //考勤管理/工作日报（所有人）
        component: () => import("../views/kqgl/syrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "工作日报（所有人）" },
          ],
        },
      },
      {
        path: "attendance/dailyreportlist",
        name: "attendance/dailyreportlist", //考勤管理/每日健康上报（所有人）
        component: () => import("../views/kqgl/syrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "每日健康上报（所有人）" },
          ],
        },
      },
      {
        path: "Signnew/report",
        name: "Signnew/report", //考勤管理/打卡操作记录
        component: () => import("../views/kqgl/dwdkView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "打卡操作记录" },
          ],
        },
      },
      {
        path: "Signnew/tracereport",
        name: "Signnew/tracereport", //考勤管理/定位信息
        component: () => import("../views/kqgl/dwdkView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "定位信息" },
          ],
        },
      },
      {
        path: "Signdaydetail/deptmonthlyreport",
        name: "Signdaydetail/deptmonthlyreport", //考勤管理/本部门月打卡信息
        component: () => import("../views/kqgl/dkxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "本部门月打卡信息" },
          ],
        },
      },
      {
        path: "signdaydetail/monthlyreport",
        name: "signdaydetail/monthlyreport", //考勤管理/打卡信息修改
        component: () => import("../views/kqgl/dkxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "打卡信息修改" },
          ],
        },
      },
      // {
      //   path: "Confidentiality/list",
      //   name: "Confidentiality/list", //考勤管理/保密承诺签署情况
      //   component: () => import("../views/kqgl/bmcnqsqkView.vue"),
      //   meta: {
      //     breadcrumbs: [
      //       { title: "首页", path: "/home/index" },
      //       { title: "考勤管理" },
      //       { title: "保密承诺签署情况" },
      //     ],
      //   },
      // },
      {
        path: "attendance/empsigndetail",
        name: "attendance/empsigndetail", //考勤管理/个人出勤统计
        component: () => import("../views/kqgl/grcqtjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "考勤管理" },
            { title: "个人出勤统计" },
          ],
        },
      },
      {
        path: "gwjspx/pxsz",
        name: "gwjspx/pxsz", //岗位晋升培训/培训设置
        component: () => import("../views/gwjspx/pxszView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升培训" },
            { title: "培训设置" },
          ],
        },
      },
      {
        path: "gwjspx/pxnrgl",
        name: "gwjspx/pxnrgl", //岗位晋升培训/培训内容管理
        component: () => import("../views/gwjspx/pxnrglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升培训" },
            { title: "培训内容管理" },
          ],
        },
      },
      {
        path: "gwjspx/pxnrckts",
        name: "gwjspx/pxnrckts", //岗位晋升培训/提升类/培训内容查看
        component: () => import("../views/gwjspx/pxnrckView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升培训" },
            { title: "提升类" },
          ],
        },
      },
      {
        path: "gwjspx/pxnrcktg",
        name: "gwjspx/pxnrcktg", //岗位晋升培训/提高类/培训内容查看
        component: () => import("../views/gwjspx/pxnrckView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升培训" },
            { title: "提高类" },
          ],
        },
      },
      {
        path: "gwjspx/pxnrck/study",
        name: "gwjspx/pxnrck/study", //岗位晋升培训/提升类/培训内容查看/学习内容
        component: () => import("../views/gwjspx/studyView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升培训" },
            // { title: "提升类" },
            // { title: "培训内容查看", path: "/home/gwjspx/pxnrck" },
            { title: "学习内容" },
          ],
        },
      },
      {
        path: "gwjspx/pxsjtj",
        name: "gwjspx/pxsjtj", //岗位晋升培训/培训数据统计
        component: () => import("../views/gwjspx/pxsjtjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升培训" },
            { title: "培训数据统计" },
          ],
        },
      },
      {
        path: "gwjsks/kssz",
        name: "gwjsks/kssz", //岗位晋升考试/考试设置
        component: () => import("../views/gwjsks/ksszView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升考试" },
            { title: "考试设置" },
          ],
        },
      },
      {
        path: "gwjsks/ksnrgl",
        name: "gwjsks/ksnrgl", //岗位晋升考试/考试内容管理
        component: () => import("../views/gwjsks/ksnrglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升考试" },
            { title: "考试内容管理" },
          ],
        },
      },
      {
        path: "gwjsks/kssjtj",
        name: "gwjsks/kssjtj", //岗位晋升考试/考试数据统计
        component: () => import("../views/gwjsks/kssjtjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升考试" },
            { title: "考试数据统计" },
          ],
        },
      },
      {
        path: "gwjsks/dkts",
        name: "gwjsks/dkts", //岗位晋升考试/提升类考试/待考界面
        component: () => import("../views/gwjsks/dkView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升考试" },
            { title: "提升类考试" },
          ],
        },
      },
      {
        path: "gwjsks/dktg",
        name: "gwjsks/dktg", //岗位晋升考试/提高类考试/待考界面
        component: () => import("../views/gwjsks/dkView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升考试" },
            { title: "提高类考试" },
          ],
        },
      },
      {
        path: "gwjsks/ks",
        name: "gwjsks/ks", //岗位晋升考试/考试页面
        component: () => import("../views/gwjsks/ksView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升考试" },
            { title: "考试" },
          ],
        },
      },
      {
        path: "gwjsks/ksjg",
        name: "gwjsks/ksjg", //岗位晋升考试/考试结果
        component: () => import("../views/gwjsks/ksjgView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "岗位晋升考试" },
            { title: "考试结果" },
          ],
        },
      },
      {
        path: "wjdcmk/wjdc",
        name: "wjdcmk/wjdc", //问卷调查模块/问卷调查/待填问卷页面
        component: () => import("../views/wjdcmk/wjdcView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "问卷调查模块" },
            { title: "待填问卷" },
          ],
        },
      },
      {
        path: "wjdcmk/twj",
        name: "wjdcmk/twj", //问卷调查模块/问卷调查/待填问卷页面/填问卷
        component: () => import("../views/wjdcmk/twjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "问卷调查模块" },
            { title: "填问卷" },
          ],
        },
      },
      {
        path: "wjdcmk/wjjg",
        name: "wjdcmk/wjjg", //问卷调查模块/问卷调查/待填问卷页面/填问卷/问卷结果
        component: () => import("../views/wjdcmk/wjjgView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "问卷调查模块" },
            { title: "问卷结果" },
          ],
        },
      },
      {
        path: "wjdcmk/wjsz",
        name: "wjdcmk/wjsz", //问卷调查模块/问卷设置
        component: () => import("../views/wjdcmk/wjszView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "问卷调查模块" },
            { title: "问卷设置" },
          ],
        },
      },
      {
        path: "wjdcmk/wjnrgl",
        name: "wjdcmk/wjnrgl", //问卷调查模块/问卷内容管理
        component: () => import("../views/wjdcmk/wjnrglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "问卷调查模块" },
            { title: "问卷内容管理" },
          ],
        },
      },
      {
        path: "wjdcmk/wjdctj",
        name: "wjdcmk/wjdctj", //问卷调查模块/问卷调查统计
        component: () => import("../views/wjdcmk/wjdctjView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "问卷调查模块" },
            { title: "问卷调查统计" },
          ],
        },
      },
      {
        path: "help/admin",
        name: "help/admin", //学习中心/管理员手册
        component: () => import("../views/xxzx/glyscView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "学习中心" },
            { title: "管理员手册" },
          ],
        },
      },
      {
        path: "help/employee",
        name: "help/employee", //学习中心/员工手册
        component: () => import("../views/xxzx/ygscView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "学习中心" },
            { title: "员工手册" },
          ],
        },
      },
      {
        path: "help/manage",
        name: "help/manage", //学习中心/管理岗手册
        component: () => import("../views/xxzx/glgscView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "学习中心" },
            { title: "管理岗手册" },
          ],
        },
      },
      {
        path: "help/problem",
        name: "help/problem", //学习中心/常见问题
        component: () => import("../views/xxzx/cjwtView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "学习中心" },
            { title: "常见问题" },
          ],
        },
      },
      {
        path: "help/add",
        name: "help/add", //学习中心/舔砖加瓦
        component: () => import("../views/xxzx/tzjwView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "学习中心" },
            { title: "舔砖加瓦" },
          ],
        },
      },
      {
        path: "dept/index",
        name: "dept/index", //公司设置/设置部门
        component: () => import("../views/gssz/szbmView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置部门" },
          ],
        },
      },
      {
        path: "position/index",
        name: "position/index", //公司设置/设置职位
        component: () => import("../views/gssz/szPWRView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置职位" },
          ],
        },
      },
      {
        path: "workgroup/index",
        name: "workgroup/index", //公司设置/设置审批组
        component: () => import("../views/gssz/szPWRView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置审批组" },
          ],
        },
      },
      {
        path: "role/index",
        name: "role/index", //公司设置/设置权限组
        component: () => import("../views/gssz/szPWRView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置权限组" },
          ],
        },
      },
      {
        path: "deptgrade/index",
        name: "deptgrade/index", //公司设置/设置部门类别
        component: () => import("../views/gssz/szbmlbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置部门类别" },
          ],
        },
      },
      {
        path: "role/node",
        name: "role/node", //公司设置/分配可用功能
        component: () => import("../views/gssz/fpkygnView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "分配可用功能" },
          ],
        },
      },
      {
        path: "role/user",
        name: "role/user", //公司设置/公司人员分组
        component: () => import("../views/gssz/gsryfzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "公司人员分组" },
          ],
        },
      },
      {
        path: "companyadmin/setmanage",
        name: "companyadmin/setmanage", //公司设置/管理专员设置
        component: () => import("../views/gssz/glzyszView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "管理专员设置" },
          ],
        },
      },
      {
        path: "signnew/scheduleset",
        name: "signnew/scheduleset", //公司设置/排班及加班信息导入/排班
        component: () => import("../views/gssz/pbjjbxxdr/pbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "排班及加班信息导入" },
            { title: "排班" },
          ],
        },
      },
      {
        path: "attendance/importovertime",
        name: "attendance/importovertime", //公司设置/排班及加班信息导入/导入加班信息
        component: () => import("../views/gssz/pbjjbxxdr/drjbxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "排班及加班信息导入" },
            { title: "导入加班信息" },
          ],
        },
      },
      {
        path: "attendance/importannual",
        name: "attendance/importannual", //公司设置/设置考勤规则/导入年假初始天数
        component: () => import("../views/gssz/szkqgz/drnjcstsView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置考勤规则" },
            { title: "导入年假初始天数" },
          ],
        },
      },
      {
        path: "attendancetimeplan/list",
        name: "attendancetimeplan/list", //公司设置/设置考勤规则/设置班次
        component: () => import("../views/gssz/szkqgz/szbcView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置考勤规则" },
            { title: "设置班次" },
          ],
        },
      },
      {
        path: "attendancesetting/attendanceplan",
        name: "attendancesetting/attendanceplan", //公司设置/设置考勤规则/设置地点
        component: () => import("../views/gssz/szkqgz/szddView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置考勤规则" },
            { title: "设置地点" },
          ],
        },
      },
      {
        path: "attendancesetting/holidaysetting",
        name: "attendancesetting/holidaysetting", //公司设置/设置考勤规则/假期规则
        component: () => import("../views/gssz/szkqgz/jqgzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置考勤规则" },
            { title: "假期规则" },
          ],
        },
      },
      {
        path: "Holidays/index",
        name: "Holidays/index", //公司设置/设置考勤规则/设置节假日
        component: () => import("../views/gssz/szkqgz/szjjrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置考勤规则" },
            { title: "设置节假日" },
          ],
        },
      },
      {
        path: "Noticeuser/index",
        name: "Noticeuser/index", //公司设置/系统通知人设置
        component: () => import("../views/gssz/xttzrszView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "系统通知人设置" },
          ],
        },
      },
      {
        path: "fldname/reportmonthly",
        name: "fldname/reportmonthly", //公司设置/设置考勤规则/设置考勤月报导出项
        component: () => import("../views/gssz/szkqgz/szkqybdcxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "公司设置" },
            { title: "设置考勤规则" },
            { title: "设置考勤月报导出项" },
          ],
        },
      },
      {
        path: "staff/index",
        name: "staff/index", //小工具/内部通讯录
        component: () => import("../views/xgj/nbtxlView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "内部通讯录" },
          ],
        },
      },
      {
        path: "WorkLog/mydailyreport",
        name: "WorkLog/mydailyreport", //小工具/日报
        component: () => import("../views/xgj/rbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "日报" },
          ],
        },
      },
      {
        path: "message/add",
        name: "message/add", //小工具/消息/发送新消息
        component: () => import("../views/xgj/xx/fsxxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "消息" },
            { title: "发送新消息" },
          ],
        },
      },
      {
        path: "message/list",
        name: "message/list", //小工具/消息/阅读/回复
        component: () => import("../views/xgj/xx/ydhfView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "消息" },
            { title: "阅读/回复" },
          ],
        },
      },
      {
        path: "signnew/signhourreport",
        name: "signnew/signhourreport", //小工具/报表/到岗人数分时段报表
        component: () => import("../views/xgj/bb/dgrsfsdbbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "报表" },
            { title: "到岗人数分时段报表" },
          ],
        },
      },
      {
        path: "customer/index",
        name: "customer/index", //小工具/通讯录/客户信息
        component: () => import("../views/xgj/txl/xxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "通讯录" },
            { title: "客户信息" },
          ],
        },
      },
      {
        path: "supplier/index",
        name: "supplier/index", //小工具/通讯录/供应商信息
        component: () => import("../views/xgj/txl/xxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "通讯录" },
            { title: "供应商信息" },
          ],
        },
      },
      {
        path: "paytool/importsix",
        name: "paytool/importsix", //小工具/个税计算/六项抵扣导入
        component: () => import("../views/xgj/gsjs/lxdcdrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "个税计算" },
            { title: "六项抵扣导入" },
          ],
        },
      },
      {
        path: "paytool/paysixlist",
        name: "paytool/paysixlist", //小工具/个税计算/已导入六项抵扣列表
        component: () => import("../views/xgj/gsjs/ydrlxdclbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "个税计算" },
            { title: "已导入六项抵扣列表" },
          ],
        },
      },
      {
        path: "paytool/import",
        name: "paytool/import", //小工具/个税计算/应发工资表导入
        component: () => import("../views/xgj/gsjs/yfgzbdrView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "个税计算" },
            { title: "应发工资表导入" },
          ],
        },
      },
      {
        path: "paytool/payrolllist",
        name: "paytool/payrolllist", //小工具/个税计算/已导入应发工资列表
        component: () => import("../views/xgj/gsjs/ydryfgzlbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "个税计算" },
            { title: "已导入应发工资列表" },
          ],
        },
      },
      {
        path: "paytool/index",
        name: "paytool/index", //小工具/个税计算/应发工资列表
        component: () => import("../views/xgj/gsjs/yfgzlbView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "小工具" },
            { title: "个税计算" },
            { title: "应发工资列表" },
          ],
        },
      },
      {
        path: "groupdept/index",
        name: "groupdept/index", //集团公司/集团组织结构
        component: () => import("../views/jtgs/jtzzjgView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "集团公司" },
            { title: "集团组织结构" },
          ],
        },
      },
      {
        path: "groupdept/groupinfo",
        name: "groupdept/groupinfo", //集团公司/集团信息
        component: () => import("../views/jtgs/jtxxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "集团公司" },
            { title: "集团信息" },
          ],
        },
      },
      {
        path: "Groupdept/groupcert",
        name: "Groupdept/groupcert", //集团公司/集团证书档案
        component: () => import("../views/jtgs/jtzsdaView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "集团公司" },
            { title: "集团证书档案" },
          ],
        },
      },
      {
        path: "Groupdept/groupcontract",
        name: "Groupdept/groupcontract", //集团公司/集团合同档案
        component: () => import("../views/jtgs/jthtdaView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "集团公司" },
            { title: "集团合同档案" },
          ],
        },
      },
      {
        path: "task/add",
        name: "task/add", //任务/发布新任务
        component: () => import("../views/rw/fbxrwView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "任务" },
            { title: "发布新任务" },
          ],
        },
      },
      {
        path: "task/index",
        name: "task/index", //任务/处理任务
        component: () => import("../views/rw/clrwView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "任务" },
            { title: "处理任务" },
          ],
        },
      },
      {
        path: "push/messages",
        name: "push/messages", //我的/消息通知
        component: () => import("../views/wd/xxtzView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "消息通知" },
          ],
        },
      },
      {
        path: "advice/mylist",
        name: "advice/mylist", //我的/建议反馈园地
        component: () => import("../views/wd/jyfkydView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "我的" },
            { title: "建议反馈园地" },
          ],
        },
      },
      {
        path: "advice/alllist",
        name: "advice/alllist", //学习中心/建议反馈管理-暂时放到这,以后可能会改
        component: () => import("../views/xxzx/gljyfkydView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "学习中心" },
            { title: "建议反馈管理" },
          ],
        },
      },
      {
        path: "companyadmin/index",
        name: "companyadmin/index", //专管员/选择分公司
        component: () => import("../views/zgy/xzfgsView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "选择分公司" },
          ],
        },
      },
      {
        path: "companyadmin/user",
        name: "companyadmin/user", //专管员/员工信息维护
        component: () => import("../views/zgy/ygxxwhView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "员工信息维护" },
          ],
        },
      },
      {
        path: "payplan/index",
        name: "payplan/index", //专管员/工资相关参数
        component: () => import("../views/zgy/gzxgcsView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "工资相关参数" },
          ],
        },
      },
      {
        path: "companyadmin/payrolldetail",
        name: "companyadmin/payrolldetail", //专管员/工资查询
        component: () => import("../views/zgy/gzcxView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "工资查询" },
          ],
        },
      },
      {
        path: "companyadmin/noticeuser",
        name: "companyadmin/noticeuser", //专管员/二级单位提醒人设置
        component: () => import("../views/zgy/ejdwtxrszView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "二级单位提醒人设置" },
          ],
        },
      },
      {
        path: "companyadmin/contract",
        name: "companyadmin/contract", //专管员/专管单位合同管理
        component: () => import("../views/zgy/zgdwhtglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "专管单位合同管理" },
          ],
        },
      },
      {
        path: "companyadmin/allcontract",
        name: "companyadmin/allcontract", //专管员/所有派遣单位合同管理
        component: () => import("../views/zgy/zgdwhtglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "所有派遣单位合同管理" },
          ],
        },
      },
      {
        path: "Companyadmin/payrolladmin",
        name: "Companyadmin/payrolladmin", //专管员/专管单位工资管理
        component: () => import("../views/zgy/zgdwgzglView.vue"),
        meta: {
          breadcrumbs: [
            { title: "首页", path: "/home/index" },
            { title: "专管员" },
            { title: "专管单位工资管理" },
          ],
        },
      },
    ],
  },
];

const router = new VueRouter({
  // 解决项目部署后页面刷新404
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

import httpApi from "@/http";
let timer = null; //心脏定时器

router.beforeEach(async (to, from, next) => {
  // console.log("路由守卫", to, from);

  // 跳转到新版标识
  if (to.query && to.query.pcType && to.query.pcType == "new") {
    window.sessionStorage.setItem("newPC", true);
  }

  if (to.path == "/login") {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    // console.log("心脏停止跳动", timer);
  } else {
    // 成功进入后心脏开始跳动
    timer = setInterval(async () => {
      // 隐藏接口 防止5分钟不和后台互动就会请求1001
      let res_HeartBeat = await httpApi.AdminApi.HeartBeat();
      //console.log(res_HeartBeat)
      if(typeof res_HeartBeat=="undefined")
        return;
      if(res_HeartBeat.code==1001)
        return;
      if (res_HeartBeat.data.status==1) {
        let msg = eval('(' + res_HeartBeat.data.data.data + ')')
        //let msg =  res_HeartBeat.data.data.data
        console.log(msg)
        store.commit('setMessage', msg.content);
      }
    }, 30000);
    // console.log("心脏开始跳动", timer);
  }

  //路径为login，直接放行
  if (to.fullPath == "/login") {
    return next();
  }

  // // 获取token
  // const tokenStr = window.sessionStorage.getItem("token");
  // //如果token不存在，跳转到login页面
  // if (!tokenStr) return next("/login");

  // /oa/index.php?s=/profileapi/baseinfo 返回如果是1001就是没有登录就跳转登录页
  let baseInfo_res = await httpApi.AdminApi.userInfo();
  // console.log("是否登录", baseInfo_res, !window.sessionStorage.getItem("user"));

  if (baseInfo_res.data.code == 0 && !window.sessionStorage.getItem("user")) {
    window.sessionStorage.setItem(
      "user",
      JSON.stringify(baseInfo_res.data.data)
    );
  } else if (baseInfo_res.data.code == 1001) {
    return next("/login");
  }

  next();
});

export default router;
